import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import HeroImage from 'components/hero/front/image';
import HeroVideo from 'components/hero/front/video';
import ResolveSlices from 'components/slices/ResolveSlices';

import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'helpers/linkResolver';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Link from 'gatsby-link';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

function Index(props) {
  const { data } = props;
  const doc = data.prismicHomepage.data;
  const menu = data.prismicGlobalLayout.data;
  if (!doc) return null;
  return (
    <Layout>
      {doc.banner_display_option ? (
        <HeroImage />
      ) : (
        <React.Fragment>
          {doc.above_banner_text?.raw && (
            <Box mb={8}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12} md={7} style={{ textAlign: 'center' }}>
                  <RichText
                    render={doc.above_banner_text?.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {/*
          <Box mb={8}>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12} md={6}>
                
                <Typography component="h1" variant="h4" align="center">
                  ATTRACTIVE INCENTIVES ARE AVAILABLE ON SELECT PLOTS FOR EARLY
                  RESERVATIONS, PLEASE{' '}
                  <a
                    style={{ color: '#BEA169' }}
                    href="https://prismic-io.s3.amazonaws.com/rectory-housing/1465b31c-6e94-47a1-b26c-f3837d016967_incentives-scheme-with+hyperlinks.pdf"
                  >
                    CLICK HERE
                  </a>{' '}
                  TO SEE OUR RANGE OF INCENTIVES.
                </Typography>
                <Typography component="h1" variant="h4" align="center">
                  Congratulations on the Coronation of Their Majesties King
                  Charles III and Queen Camilla
                </Typography>
                <img
                  style={{
                    width: '250px',
                    margin: '50px auto',
                    display: 'block',
                  }}
                  alt=""
                  src="https://images.prismic.io/rectory-housing/b8fbdd2c-ca68-4002-9264-e3b6835359dc_image006.jpg?auto=compress,format"
                />
              </Grid>
            </Grid>
          </Box>
          */}
          <HeroVideo
            isFront={true}
            videoUrl={doc.video.url ? doc.video.localFile.publicURL : ''}
            mobileVideoUrl={
              doc.mobile_video.url ? doc.mobile_video.localFile.publicURL : ''
            }
            title={doc.hero_title ? doc.hero_title.raw : ''}
            strapline={doc.hero_strapline ? doc.hero_strapline.raw : ''}
            overlayColor={doc.overlay_color ? doc.overlay_color : '#000'}
            overlayOpacity={doc.overlay_opacity ? doc.overlay_opacity : '0.2'}
            menu={menu}
            search={true}
          />
        </React.Fragment>
      )}
      {doc.body && <ResolveSlices data={doc.body} />}
    </Layout>
  );
}

export default withPrismicPreview(Index, [
  {
    repositoryName: 'rectory-housing',
    linkResolver,
  },
]);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    prismicGlobalLayout {
      data {
        main_menu {
          link_name
          link {
            uid
            type
            id
          }
        }
      }
    }

    prismicHomepage {
      _previewable
      data {
        above_banner_text {
          html
          raw
          text
        }
        hero_strapline {
          html
          raw
          text
        }
        hero_title {
          html
          raw
          text
        }
        banner_display_option
        video {
          url
          localFile {
            publicURL
          }
        }
        mobile_video {
          url
          localFile {
            publicURL
          }
        }
        overlay_color
        overlay_opacity
        body {
          ... on PrismicHomepageDataBodyTitleAndText {
            slice_type
            primary {
              alignment
              content_grid_width
              display_icon_below_title
              title {
                html
                raw
                text
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageDataBodyLatestDevelopments {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
            }
            items {
              development {
                uid
                type
                id
                document {
                  ... on PrismicDevelopment {
                    id
                    data {
                      title {
                        html
                        raw
                        text
                      }
                      development_location {
                        html
                        raw
                        text
                      }
                      development_logo {
                        url
                      }
                      preview_image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageDataBodyLineSpacer {
            slice_type
            primary {
              color
              thickness
            }
          }
          ... on PrismicHomepageDataBody13ImageGrid {
            id
            slice_type
            primary {
              border_color
              border_weight
            }
            items {
              image {
                url
              }
              link {
                uid
                type
                id
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageDataBodyFullWidthImage {
            slice_type
            primary {
              content_layout_theme
              title {
                html
                raw
                text
              }
              link {
                uid
                type
                id
              }
              image {
                url
                alt
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageDataBody5050ColumnContentBlock {
            slice_type
            primary {
              title {
                html
                raw
                text
              }
            }
            items {
              content_block {
                html
                raw
                text
              }
            }
          }
        }
      }
    }
  }
`;
